import { debounce, isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useProductApi } from "./apiHooks";
import { useMultiLanguageSetting } from "./useMultiLanguageSetting";

export function useProductReanalysis(id, formData, apiData) {
  const [score, setScore] = useState(0);
  const [optimizationData, setOptimizationData] = useState({});
  const [focusKeywordSuggestions, setFocusKeywordSuggestions] = useState([]);

  const { selectedLanguage, primaryLanguage } = useMultiLanguageSetting();

  const productApi = useProductApi();
  const { mutate: reanalyseProduct } = useMutation({
    mutationFn: () =>
      productApi.calculateOptimizationData(
        id,
        formData,
        selectedLanguage === primaryLanguage ? null : selectedLanguage
      ),
    onSuccess: ({ score, optimizationData, focusKeywordSuggestions }) => {
      setScore(score);
      setOptimizationData(optimizationData);
      // setFocusKeywordSuggestions(focusKeywordSuggestions);
    },
  });

  const _debReanalyseProduct = useCallback(debounce(reanalyseProduct, 1200), []);

  useEffect(() => {
    if (!isEmpty(formData)) _debReanalyseProduct();
  }, [formData]);

  useEffect(
    function resetStateOnDataChange() {
      if (!isEmpty(apiData)) {
        setScore(apiData.product.score);
        setOptimizationData(apiData.optimizationData);
        setFocusKeywordSuggestions(apiData.focusKeywordSuggestions);
      }
    },
    [apiData]
  );

  return {
    score,
    optimizationData,
    focusKeywordSuggestions,
  };
}
