export default [
  { label: "English", value: "en" },
  { label: "हिन्दी", value: "hi" },
  { label: "Français", value: "fr" },
  { label: "Español", value: "es" },
  { label: "Nederlands", value: "nl" },
  { label: "Italiano", value: "it" },
  { label: "Deutsch", value: "de" },
  { label: "中国人", value: "zh" },
  { label: "日本語", value: "ja" },
  { label: "Polish", value: "pl" },
];
