import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppQuery, useMenuItems } from "../hooks/index.js";

import GlobalNotifications from "@/components/notifications/GlobalNotifications.jsx";
import { useBackupRestoreStatus } from "@/lib/hooks/settings/backup-restore/index.jsx";
import { NavMenu } from "@shopify/app-bridge-react";
import { isEmpty } from "lodash";
import { createContext, Suspense, useCallback, useContext, useEffect, useState } from "react";
import { browserEvents, emitter } from "../config/index.js";
import { useUserApi } from "../hooks/apiHooks/useUserApi.js";
import { useThirdPartyScripts } from "../hooks/useThirdPartyScripts.js";
import { setBlogSyncStatus } from "../store/features/BlogSync.js";
import { setCampaign } from "../store/features/Campaign.js";
import { setCollectionSyncStatus } from "../store/features/CollectionSync.js";
import { setAllBanner } from "../store/features/HiddenBanner.js";
import { setUnreadNotificationsCount } from "../store/features/Notifications.js";
import { setOnboardStep } from "../store/features/OnboardStep.js";
import { setOptimizationTaskOngoing } from "../store/features/OptimizationTask.js";
import { setPageSyncStatus } from "../store/features/PageSync.js";
import { setProductSyncStatus } from "../store/features/ProductSync.js";
import { updateUser } from "../store/features/User.js";
import { scrollToTop } from "../utility/helpers.jsx";
import queryKeys from "../utility/queryKeys.js";
import PusherProvider from "./PusherProvider.jsx";

const StoreSeoContext = createContext({ loadLowPriorityContent: false, doManualRefresh: false });

/**
 *
 * @returns {{ doManualRefresh: boolean, loadLowPriorityContent: boolean }}
 */
export const useStoreSeo = () => useContext(StoreSeoContext);

export default function StoreSeoProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userApi = useUserApi();
  const user = useSelector((state) => state.user);
  const onboardStep = useSelector((state) => state.onboardStep);
  const [searchParams, setSearchParams] = useSearchParams();
  const refreshParam = searchParams.get("refresh");
  const doManualRefresh = refreshParam === "1";

  const showTableLoader = location.pathname === "/" || location.pathname.match(/.*(?:(?:products))/gim);

  const [loadLowPriorityContent, setLoadLowPriorityContent] = useState(false);
  const [thirdPartyScriptsLoaded, setThirdPartyScriptsLoaded] = useState(false);
  const [appContentDisplayed, setAppContentDisplayed] = useState(false);

  const displayAppContent = useCallback(() => {
    if (appContentDisplayed) return;

    // document.getElementById("static-loader").remove();
    // document.getElementById("static-loader").style.opacity = "0";
    // document.getElementById("app").style.display = "initial";

    const staticLoaderElement = document.getElementById("static-loader");
    // const staticLoaderHeight = staticLoaderElement.offsetHeight;
    const appElement = document.getElementById("storeseo-wrapper");
    appElement.style.transition = "all .35s ease-in-out";

    appElement.style.translate = "none";
    // appElement.style.zIndex = "9";
    // appElement.style.transform = `translateY(2500px)`;

    setTimeout(() => {
      appElement.style.zIndex = "1";
      appElement.style.opacity = "1";

      if (staticLoaderElement) staticLoaderElement.style.zIndex = "-9";

      appElement.click();
    }, 350);

    setTimeout(() => staticLoaderElement?.remove(), 1000);

    setTimeout(() => setLoadLowPriorityContent(true), 1600);

    setAppContentDisplayed(true);
  }, []);

  useEffect(function listenForPageDataLoadEvent() {
    emitter.on(browserEvents.PAGE_DATA_LOADED, displayAppContent);

    return () => emitter.off(browserEvents.PAGE_DATA_LOADED, displayAppContent);
  }, []);

  useEffect(function triggerAppContentDisplayAfterTimeout() {
    const delay = location.pathname.match(/(?:(products)|(?:pages)|(?:collections)|(?:articles))\/.*/gim) ? 5500 : 950;
    setTimeout(displayAppContent, delay);
  }, []);

  useEffect(
    function preloadSettingsLayout() {
      if (!loadLowPriorityContent) return;

      import("../layouts/SettingsLayout");
    },
    [loadLowPriorityContent]
  );

  useEffect(
    function scrollToTopOnRouteChange() {
      // console.log("FRONTEND_MAINTENANCE_MODE =", process.env?.FRONTEND_MAINTENANCE_MODE);

      // if (process.env?.FRONTEND_MAINTENANCE_MODE === "true" && !location.pathname.includes("maintenance")) {
      //   navigate("/maintenance");
      // }

      // if (process.env?.FRONTEND_MAINTENANCE_MODE !== "true" && location.pathname.includes("maintenance")) {
      //   navigate("/");
      // }
      scrollToTop();
    },
    [location.pathname]
  );

  useEffect(() => {
    if (!isEmpty(user)) {
      const subsRegex = /\/(checkout|subscription)\/\w+/;
      if (location.pathname.match(subsRegex)?.length === undefined) {
        if (location.pathname !== "subscription" && !user.isSubscribed) {
          navigate("/subscription");
          return;
        }

        const onboardRegex =
          /\/onboard(\/(success|verify))?|\/credit-bundles|\/verify-purchase|\/verify-subscription/gi;
        if (onboardStep && location.pathname.match(onboardRegex)?.length === undefined) {
          navigate("/onboard");
        }
      }
    }
  }, [user, onboardStep, location.pathname]);

  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());
    delete params.refresh;
    setSearchParams(params, { replace: true });
  }, [doManualRefresh]);

  const { data } = useAppQuery({
    queryKey: [queryKeys.AUTH_USER],
    queryFn: () => userApi.getAuthUserData(),
    reactQueryOptions: {
      keepPreviousData: true,
    },
  });

  const { data: checkStatus, refetch: fetchBetterDocAppInstallationStatus } = useAppQuery({
    queryKey: [queryKeys.BETTERDOCS_INSTALL_STATUS],
    queryFn: () => userApi.getBetterDocsAppInstallStatus(),
    reactQueryOptions: {
      keepPreviousData: true,
      enabled: false,
    },
  });

  const { data: storeBackupRestoreStatus } = useBackupRestoreStatus({
    queryKey: [queryKeys.BACKUP_RESTORE_STATUS],
  });

  useEffect(() => {
    if (data) {
      dispatch(updateUser(data.user));
      dispatch(setCampaign(data.campaign));
      dispatch(setProductSyncStatus(data.user.productSyncOngoing));
      dispatch(setCollectionSyncStatus(data.user.collectionSyncOngoing));
      dispatch(setBlogSyncStatus(data.user.blogSyncOngoing));
      dispatch(setPageSyncStatus(data.user.pageSyncOngoing));
      dispatch(setUnreadNotificationsCount(data.unreadNotifications));
      dispatch(setOptimizationTaskOngoing(data.optimizationTaskOngoing));
      dispatch(setOnboardStep(data.user.onboardStep));
      dispatch(setAllBanner(data.hiddenBanner));
      dispatch(
        updateUser({
          betterDocsInstallationStatus: checkStatus?.status || false,
          backupRestoreStatus: storeBackupRestoreStatus,
        })
      );
      // dispatch(setDocSyncStatus(data.user.docSyncOngoing));
    }
  }, [data, checkStatus, storeBackupRestoreStatus]);

  const { crisp, googleAnalytics } = useThirdPartyScripts();
  useEffect(() => {
    if (!loadLowPriorityContent || thirdPartyScriptsLoaded) return;

    // window.Userback = window.Userback || {};
    // Userback.access_token = "6133|60336|7fdtokaCkPgvcaECH57NG8UCNx9HT9xZTdOrZI5Oy1FJTZkOSK";
    // (function (d) {
    //   let s = d.createElement("script");
    //   s.async = true;
    //   s.src = "https://static.userback.io/widget/v1.js";
    //   (d.head || d.body).appendChild(s);
    // })(document);

    crisp(user);
    googleAnalytics();
    setThirdPartyScriptsLoaded(true);

    const betterDocInstallationStatus = setTimeout(fetchBetterDocAppInstallationStatus, 2500);

    () => {
      clearTimeout(betterDocInstallationStatus);
    };
  }, [user, loadLowPriorityContent]);

  const navigationLinks = useMenuItems();
  const shoAppNavMenu = !!location.pathname.match(/^(?!\/login|\/exitIframe).*$/);

  return (
    <>
      {shoAppNavMenu && (
        <NavMenu>
          {navigationLinks.map((link, index) => {
            return (
              <Link
                key={index}
                to={link.destination}
                rel={link.label === "Home" ? "home" : ""}
              >
                {link.label}
              </Link>
            );
          })}
        </NavMenu>
      )}
      <Suspense fallback={<></>}>
        <StoreSeoContext.Provider value={{ loadLowPriorityContent, doManualRefresh }}>
          <PusherProvider>
            {children}
            {loadLowPriorityContent && <GlobalNotifications />}
          </PusherProvider>
        </StoreSeoContext.Provider>
      </Suspense>
    </>
  );
}
