import { BlockStack, Button, Card, InlineStack, Text } from "@shopify/polaris";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import seoApps from "storeseo-enums/seoApps";
import { useAppQuery, useOnboardApi, useUtilityApi } from "../../hooks";
import queryKeys from "../../utility/queryKeys";
import SeoMigratorOption from "../seo-setting/SEOMigratorOption";
import StepCompleted from "./StepCompleted";

const TITLE = "Migrate Your SEO Data From Another App";

export default function InstallApps({
  completed = false,
  active = false,
  onComplete = () => {},
  onBack = () => {},
  isFirstStep = false,
}) {
  const { t } = useTranslation();
  const utilityApi = useUtilityApi();
  const onboardApi = useOnboardApi();

  const [selectedSeoApp, setSelectedSeoApp] = useState(null);

  const { data: installedApps = [], isFetching } = useAppQuery({
    queryKey: queryKeys.INSTALLED_APPS_LIST,
    queryFn: utilityApi.getListOfInstalledSeoApps,
  });

  const { mutate: migrateData, isLoading: isUpdating } = useMutation({
    mutationFn: ({ skip = false }) => onboardApi.submitDataMigrationInfo(skip ? null : selectedSeoApp),
    onSuccess: onComplete,
  });

  const handleSkip = () => {
    setSelectedSeoApp(null);
    migrateData({ skip: true });
  };

  if (completed || !active) {
    return (
      <StepCompleted
        title={TITLE}
        completed={completed}
      />
    );
  }

  return (
    <Card background="bg-fill-secondary">
      <InlineStack blockAlign="center">
        <span className="check-circle"></span>
        <Text
          variant="headingSm"
          as="h3"
        >
          {t(TITLE)}
        </Text>
      </InlineStack>
      <div style={{ padding: "5px 0 0 30px" }}>
        <BlockStack gap="400">
          <Text as="p">
            {t(
              "Using multiple SEO apps together may cause unintended side effects! StoreSEO can detect any other SEO apps you have installed on your store & help you migrate important SEO data from those apps to StoreSEO. Please note that it's a one-way process & this will update all your product data; your existing SEO score may also change after this."
            )}
          </Text>
          {Object.values(seoApps).map((app) => {
            return (
              installedApps.includes(app.name) && (
                <SeoMigratorOption
                  key={app.handle}
                  option={app}
                  checked={selectedSeoApp === app.name}
                  onChange={() => setSelectedSeoApp(app.name)}
                />
              )
            );
          })}
          <InlineStack
            align="end"
            gap="300"
          >
            {!isFirstStep && <Button onClick={onBack}>{t("Back")}</Button>}
            <Button
              onClick={handleSkip}
              disabled={selectedSeoApp && isUpdating}
              loading={!selectedSeoApp && isUpdating}
            >
              {t("Skip for now")}
            </Button>
            <Button
              onClick={migrateData}
              disabled={!selectedSeoApp}
              loading={selectedSeoApp && isUpdating}
              variant="primary"
            >
              {t("Migrate data")}
            </Button>
          </InlineStack>
        </BlockStack>
      </div>
    </Card>
  );
}
