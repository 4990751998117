import { BlockStack, Button, Card, InlineStack, Text } from "@shopify/polaris";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppQuery, useOnboardApi } from "../../hooks";
import RadialChart from "../charts/RadialChart";
import OptimizationRow from "../common/OptimizationRow";
import StepCompleted from "./StepCompleted";

const TITLE = "Store Analysis & Optimization";

export default function StoreAnalysis({
  completed = false,
  active = false,
  onComplete = () => {},
  onBack = () => {},
  isFirstStep = false,
}) {
  const onboardApi = useOnboardApi();
  const { t } = useTranslation();

  const { data, isLoading } = useAppQuery({
    queryKey: "ONBOARD_ANALYSIS",
    queryFn: () => onboardApi.getAnalysisData(),
    reactQueryOptions: {
      staleTime: 0,
      refetchInterval: false,
      onSuccess: (data) => {
        if (data) {
          setAnalysis(data.analysis);
          setScore(data.analysis.overallScore.replace("%", ""));
        }
      },
    },
  });

  const { isLoading: isContentLoading } = useAppQuery({
    queryKey: "ONBOARD_OPTIMIZATION",
    queryFn: () => onboardApi.getOptimizationData(),
    reactQueryOptions: {
      staleTime: 0,
      refetchInterval: false,
      onSuccess: ({ optimizationData = [] }) => {
        setOptimizations(optimizationData);
      },
    },
  });

  const [analysis, setAnalysis] = useState({});
  const [score, setScore] = useState(0);
  const [optimizations, setOptimizations] = useState([]);

  if (completed || !active) {
    return (
      <StepCompleted
        title={TITLE}
        completed={completed}
      />
    );
  }

  return (
    <Card background="bg-fill-secondary">
      <InlineStack blockAlign="center">
        <span className="check-circle"></span>
        <Text
          variant="headingSm"
          as="h3"
        >
          {t("Store Analysis & Optimization")}
        </Text>
      </InlineStack>
      <div style={{ padding: "5px 0 0 30px" }}>
        <BlockStack gap="400">
          <Text as="p">{t("Sync your products and run SEO analysis to start fixing your SEO Issues")}</Text>
          <Card>
            <InlineStack
              gap="200"
              wrap={false}
              align="space-between"
              blockAlign="center"
            >
              <BlockStack gap="300">
                <Text
                  fontWeight="semibold"
                  as="p"
                >
                  {t("Number of products synced:")}{" "}
                  <Text
                    fontWeight="medium"
                    as="span"
                    tone="success"
                  >
                    {analysis.totalCount}
                  </Text>
                </Text>
                <Text
                  fontWeight="semibold"
                  as="p"
                >
                  {t("Number of products analyzed:")}{" "}
                  <Text
                    fontWeight="medium"
                    as="span"
                    tone="success"
                  >
                    {analysis.analysedCount}
                  </Text>
                </Text>
                <Text
                  fontWeight="semibold"
                  as="p"
                >
                  {t("Number of products has SEO issues:")}{" "}
                  <Text
                    fontWeight="medium"
                    as="span"
                    tone="success"
                  >
                    {analysis.seoIssues}
                  </Text>
                </Text>
              </BlockStack>
              <div style={{ maxWidth: 90 }}>
                <RadialChart
                  score={score}
                  size="medium"
                  dimension={70}
                />
              </div>
            </InlineStack>
          </Card>
          {optimizations.length > 0 && (
            <Text
              variant="headingSm"
              as="h4"
            >
              {t("Possible Optimization")}
            </Text>
          )}

          {optimizations.map((optimization) => (
            <OptimizationRow
              key={optimization.key}
              optimization={optimization}
            />
          ))}
          <InlineStack
            align="end"
            gap="200"
          >
            {!isFirstStep && <Button onClick={onBack}>{t("Back")}</Button>}
            <Button
              onClick={onComplete}
              variant="primary"
            >
              {t("Next")}
            </Button>
          </InlineStack>
        </BlockStack>
      </div>
    </Card>
  );
}
